/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>We build awesome products</h2>
          <h5 className={classes.description}>
            
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="1. Design"
            description={
              <span>
                <p>
                  We work with you to determine the best solution for your needs{" "}
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon={Gesture}
            iconColor="info"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="2. Develop"
            description={
              <span>
                <p>
                  Designers, Developers, Content Creators, Heros. We'd be happy to hear about your talents. Connect with us!
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon={Build}
            iconColor="info"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="3. Make Edits"
            description={
              <span>
                <p>
                  We will continue to refine our solution until you are impressed with the outcome! {" "}
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon="mode_edit"
            iconColor="info"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
